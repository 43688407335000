import fetch from "auth/FetchInterceptor";

const MailManagementService = {
  getEmailAddresses: () => {
    try {
      return fetch({
        url: "/mail/mail_mng",
        method: "get",
      });
    } catch (error) {
      throw new Error(error.message || "Failed to fetch email addresses");
    }
  },
  toggleUseMailbox: async function (account) {
    console.log("Sending toggle request for:", account);
    try {
      const response = await fetch({
        url: `/mail/mail_mng/${account}/toggle`,
        method: "patch",
      });
      console.log("Toggle response received:", response);
      return response;
    } catch (error) {
      console.error("Error toggling mailbox:", error);
      throw error; // Re-throw the error for proper handling
    }
  },
  disconnectEmailAddress: async function (account) {
    console.log("Disconnecting email address:", account);
    try {
      const response = await fetch({
        url: `/mail/mail_mng/${account}/disconnect`,
        method: "patch",
      });
      console.log("Disconnect response received:", response);
      return response;
    } catch (error) {
      console.error("Error disconnecting email address:", error);
      throw error;
    }
  },
  getAuthCode: (provider, scope) => {
    try {
      return fetch({
        url: `/mail/auth_code`,
        method: "post",
        data: {
          email_provider: provider,
          scope_type: scope,
        },
      });
    } catch (error) {
      throw new Error(error.message || "Failed to get auth uri");
    }
  },

  getEmailHealth: (provider) => {
    try {
      return fetch({
        url: `/mail/mailbox-health`,
        method: "get",
      });
    } catch (error) {
      throw new Error(error.message || "Failed to get auth uri");
    }
  },

  getTrainingEmailAddresses: () => {
    try {
      return fetch({
        url: "/mail/listing/training-mails",
        method: "get",
      });
    } catch (error) {
      throw new Error(error.message || "Failed to fetch email addresses");
    }
  },
};

export default MailManagementService;

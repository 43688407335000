import fetch from 'auth/FetchInterceptor'

const DashboardService = {}

DashboardService.getGraphData = function (data) {
    return fetch({
        url: `dashboard/response?campaign_id=${data?.id}&time=${data?.time}`,
        method: 'get',
    })
}

DashboardService.getPendingEmail = function (id) {
    return fetch({
        url: `dashboard/email/pending?campaign_id=${id}`,
        method: 'get',
    })
}

DashboardService.getQueuedEmail = function (id) {
    return fetch({
        url: `dashboard/email/queue?campaign_id=${id}`,
        method: 'get',
    })
}



export default DashboardService;
import Icon, { LogoutOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Avatar, Checkbox, Col, Dropdown, Row, Typography } from "antd";
import Flex from "components/shared-components/Flex";
import {
  FONT_SIZES,
  FONT_WEIGHT,
  MEDIA_QUERIES,
  SPACER,
} from "constants/ThemeConstant";
import { useProfile } from "hooks/useProfile";
import { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useDispatch } from "react-redux";
import { signOut } from "store/slices/authSlice";
import { onHeaderNavColorChange, onSwitchTheme } from "store/slices/themeSlice";
import NavItem from "./NavItem";
import CalendlyModal from "components/revrag-components/CalendlyModal";
import { SettingsSvg } from "assets/svg/settings";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const SettingsIcon = (props) => <Icon component={SettingsSvg} {...props} />;

const { Text } = Typography;

const Icons = styled.div(() => ({
  fontSize: FONT_SIZES.LG,
}));

const Profile = styled.div(() => ({
  display: "flex",
  alignItems: "center",
}));

const UserInfo = styled("div")`
  padding-left: ${SPACER[2]};

  @media ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`;

const Name = styled.div(() => ({
  fontWeight: FONT_WEIGHT.SEMIBOLD,
  // color:"#fff"
}));

const Title = styled.span(() => ({
  opacity: 0.8,
}));

const MenuItemSignOut = (props) => {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <div onClick={handleSignOut}>
      <Flex alignItems="center" gap={SPACER[2]}>
        <Icons>
          <LogoutOutlined />
        </Icons>
        <span>{props.label}</span>
      </Flex>
    </div>
  );
};

const AccountSetting = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSetting = () => {
    // dispatch(signOut());
    navigate(`${APP_PREFIX_PATH}/apps/settings`);
  };

  return (
    <div onClick={handleSetting}>
      <Flex alignItems="center" gap={SPACER[2]}>
        <SettingsIcon />

        <span>{props.label}</span>
      </Flex>
    </div>
  );
};

const ThemeSetting = (props) => {
  const dispatch = useDispatch();
  const { switcher, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked) => {
    onHeaderNavColorChange("");
    const changedTheme = isChecked ? "dark" : "light";
    dispatch(onSwitchTheme(changedTheme));
    switcher({ theme: themes[changedTheme] });
  };

  return (
    <div>
      <Flex alignItems="center" gap={SPACER[2]}>
        <Checkbox
          //   defaultChecked={isItemPresent}
          onChange={toggleTheme}
          className="custom-checkbox"
        />
        <span>{props.label}</span>
      </Flex>
    </div>
  );
};

const items = [
  {
    key: "Setting",
    label: <AccountSetting label="Settings" />,
  },
  {
    key: "Sign Out",
    label: <MenuItemSignOut label="Sign Out" />,
  },
];

const UserAvatar = ({ imageSrc, name }) => {
  // Get the first letter of the name
  const firstLetter = name ? name.charAt(0).toUpperCase() : "";

  return (
    <Avatar
      src={imageSrc} // This will render the image if available
      style={{
        backgroundColor: imageSrc ? "transparent" : "#5F26C8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!imageSrc && firstLetter}{" "}
      {/* Render the first letter if no image source */}
    </Avatar>
  );
};

export const NavProfile = ({ mode }) => {
  const { profileData } = useProfile();
  return (
    <Dropdown placement="bottomRight" menu={{ items }} trigger={["click"]}>
      <NavItem mode={mode}>
        <Profile>
          {/* <Avatar src="/img/avatars/thumb-1.jpg" /> */}
          <UserAvatar name={profileData?.username} />
          <UserInfo className="profile-text">
            <Name>{profileData?.username}</Name>
            {/* <Title>Frontend Developer</Title> */}
          </UserInfo>
        </Profile>
      </NavItem>
    </Dropdown>
  );
};

const getTimeLeft = (expiryDate) => {
  const now = new Date();
  const expiry = new Date(expiryDate); // Convert expiryDate to Date object
  const diff = expiry - now; // Calculate the difference in milliseconds

  if (diff <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const CustomCountdown = ({ ExpiryDatae }) => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(ExpiryDatae));
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getTimeLeft(ExpiryDatae));
    }, 1000);

    return () => clearInterval(interval);
  }, [ExpiryDatae]); // Update the effect when ExpiryDatae changes

  const handleCalendlyOpenModal = () => {
    // setIsCalendlyModalOpen(true);
    window.location.href = "https://www.revrag.ai/book-a-demo";
  };

  const handleCalendlyCloseModal = () => {
    setIsCalendlyModalOpen(false);
  };

  return (
    <div
      className="custom-countdown"
      style={{ display: "flex", width: "300px", color: "#000" }}
    >
      {timeLeft.days} Days remaining in free trial.
      <div
        style={{
          marginLeft: "5px",
          color: "#5F26C9",
          textDecoration: "underline",
        }}
        onClick={handleCalendlyOpenModal}
      >
        Upgrade
      </div>
      {isCalendlyModalOpen && (
        <CalendlyModal
          isOpen={isCalendlyModalOpen}
          onModalClose={handleCalendlyCloseModal}
        />
      )}
    </div>
  );
};

export const NavCounter = ({ mode }) => {
  const { profileData } = useProfile();
  return (
    <NavItem>
      <Row gutter={16}>
        <Col xs={0} sm={12} span={12}>
          <CustomCountdown ExpiryDatae={profileData?.freeTrialEndDate} />
        </Col>
      </Row>
    </NavItem>
  );
};

export default NavProfile;

import React from "react";
import { PopupModal } from "react-calendly";

const CalendlyModal = ({ isOpen, onModalClose, pageSettings, utm, prefill }) => {
  return (
    <div>
      {isOpen && (
        <PopupModal
          url="https://calendly.com/ashutosh-revrag/25mins"
          pageSettings={pageSettings}
          utm={utm}
          prefill={prefill}
          onModalClose={onModalClose}
          open={isOpen}
          rootElement={document.getElementById("root")}
        />
      )}
    </div>
  );
};

export default CalendlyModal;

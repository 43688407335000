import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CampaignService from "services/CampaignService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  campaigns: [],
  data: null,
  campaignData: null,
  error: null,
  sampleLeads: [],
  campaignInfo: null,
  playbooks: [],
  playbookSample: null,
};

export const getLeadCount = createAsyncThunk(
  "campaign/getLeadCount",
  async (id) => {
    try {
      const response = await CampaignService.getLeads(id);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const uploadblacklistCsv = createAsyncThunk(
  "campaign/uploadblacklistCsv",
  async (data) => {
    const formData = new FormData();
    formData.append("file", data);
    try {
      const response = await CampaignService.uploadblackListCsv(formData);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const uploadtargetCsv = createAsyncThunk(
  "campaign/uploadblacklistCsv",
  async (data) => {
    const formData = new FormData();
    formData.append("file", data);
    try {
      const response = await CampaignService.uploadtargetCsv(formData);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const uploadCsv = createAsyncThunk(
  "campaign/uploadCsv",
  async (data) => {
    const formData = new FormData();
    formData.append("file", data);
    try {
      const response = await CampaignService.uploadCsv(formData);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async (data) => {
    try {

      const response = await CampaignService.updateCampaign(data);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async (data) => {
    try {
      const response = await CampaignService.createCampaign(data);
      return response;
    } catch (err) {
      return err.response?.data?.message || "Error";
    }
  }
);

export const fetchCampaigns = createAsyncThunk(
  "campaign/getCampaign",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CampaignService.getCampaign();
      return response.campaigns;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const fetchLeads = createAsyncThunk(
  "campaign/getSampleLeads",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.getSampleLeads(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const duplicateCampaign = createAsyncThunk(
  "campaign/duplicateCampaign",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.duplicateCampaign(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Delete Campaign 
export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.deleteCampaign(id);
      return { id, data: response };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const fetchCampaignInfo = createAsyncThunk(
  "campaign/getCampaignInfo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.getCampaignInfo(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const pauseCampaign = createAsyncThunk(
  "campaign/pauseCampaign",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.pauseCampaign(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const liveCampaign = createAsyncThunk(
  "campaign/liveCampaign",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.liveCampaign(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const publishCampaign = createAsyncThunk(
  "campaign/publishCampaign",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.publishCampaign(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateVisibility = createAsyncThunk(
  "campaign/updateVisibility",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CampaignService.updateVisibility(data);
      return { response, id: data?.campaignId, status: data?.status };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const fetchplaybooks = createAsyncThunk(
  "campaign/getPlaybooks",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.getPlaybooks();
      return response?.playbooks;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const fetchplaybookSample = createAsyncThunk(
  "campaign/getPlaybookSample",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CampaignService.getPlaybookSample(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const fetchCampaignTimimg = createAsyncThunk(
  "campaign/fetchCampaignTiming",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CampaignService.fetchCampaignTiming(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const fetchCustomizeSamplePlaybook = createAsyncThunk(
  "campaign/getCustomizeSamplePlaybook",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CampaignService.getCustomizeSamplePlaybook(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
    clearCampaignInfo(state) {
      state.campaignInfo = null;
    },
    clearCampaignData(state) {
      state.campaignData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Campaign
      .addCase(createCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {

        state.loading = true;
        state.campaignData = action.payload;
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      // Fetch Campaigns
      .addCase(fetchCampaigns.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // fetchSample leads
      .addCase(fetchLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.sampleLeads = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // fetch Campaign info by id
      .addCase(fetchCampaignInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCampaignInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignInfo = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchCampaignInfo.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // fetch Playbooks info id
      .addCase(fetchplaybooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchplaybooks.fulfilled, (state, action) => {
        state.loading = false;
        state.playbooks = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchplaybooks.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // fetch Playbook Sample
      .addCase(fetchplaybookSample.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchplaybookSample.fulfilled, (state, action) => {
        state.loading = false;
        state.playbookSample = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchplaybookSample.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // fetch Customize Playbook Sample
      .addCase(fetchCustomizeSamplePlaybook.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomizeSamplePlaybook.fulfilled, (state, action) => {
        state.loading = false;
        state.playbookSample = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchCustomizeSamplePlaybook.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // update Lead count data
      .addCase(getLeadCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeadCount.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignData = {
          ...state.campaignData,
          totalLeadCount: action.payload?.totalLeadCount,
        };
        state.showMessage = false;
      })
      .addCase(getLeadCount.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // update Visibility count data
      .addCase(updateVisibility.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVisibility.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = state.campaigns.map(campaign =>
          campaign.campaignId === action.payload.id
            ? { ...campaign, campaignVisibility: action.payload.status }
            : campaign
        );
        state.showMessage = false;
      })
      .addCase(updateVisibility.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // duplicate campaign

      .addCase(duplicateCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(duplicateCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = [action?.payload, ...state.campaigns];
        state.showMessage = false;
      })
      .addCase(duplicateCampaign.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })


      // delete campaign

      .addCase(deleteCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        const { id } = action.payload; // Destructure the id from the action payload
        
        // Filter out the campaign with matching campaignId
        state.campaigns = state.campaigns.filter(campaign => campaign.campaignId !== id);
        
        state.loading = false;
        state.showMessage = false;
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearError, clearCampaignInfo, clearCampaignData } =
  campaignSlice.actions;

export default campaignSlice.reducer;

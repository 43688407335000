import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import IntegrationService from "services/IntegrationService";

export const initialState = {
  loading: false,
  session_token: null,
  application_list: [],
  slackAuthUri: null,
  linked_account: null,
  isConnected: false,
  error: null,
};

export const fetchSession = createAsyncThunk(
  "integration/getSession",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IntegrationService.getIntegrationSession();
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const linkAccount = createAsyncThunk(
  "integration/linkAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IntegrationService.setIntegrationAccount();
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const fetchApplication = createAsyncThunk(
  "integration/getApplicationList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IntegrationService.getApplicationList();
      return response.application;
    } catch (err) {
      return rejectWithValue(err.response?.application?.message || "Error");
    }
  }
);

export const fetchSlackAuthUri = createAsyncThunk(
  "integration/getSlackAuthUri",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IntegrationService.getSlackAuthUri();
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
// getSlackIsConnected

export const fetchSlackIsConnected = createAsyncThunk(
  "integration/getSlackIsConnected",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IntegrationService.getSlackIsConnected();
      return response.isConnected;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch session
      .addCase(fetchSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.loading = false;
        state.session_token = action.payload;
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.loading = false;
        state.session_token = action.payload;
      })
      // set linked account
      .addCase(linkAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(linkAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.linked_account = action.payload;
      })
      .addCase(linkAccount.rejected, (state, action) => {
        state.loading = false;
        state.linked_account = action.payload;
      })

      // Fetch Application
      .addCase(fetchApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.application_list = action.payload;
      })
      .addCase(fetchApplication.rejected, (state, action) => {
        state.loading = false;
        state.application_list = action.payload;
      })
      // Fetch Slack Auth Uri
      .addCase(fetchSlackAuthUri.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSlackAuthUri.fulfilled, (state, action) => {
        state.loading = false;
        state.slackAuthUri = action.payload;
      })
      .addCase(fetchSlackAuthUri.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Slack Is Connected
      .addCase(fetchSlackIsConnected.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSlackIsConnected.fulfilled, (state, action) => {
        state.loading = false;
        state.isConnected = action.payload;
      })
      .addCase(fetchSlackIsConnected.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = integrationSlice.actions;

export default integrationSlice.reducer;

import fetch from "auth/FetchInterceptor";

const profileService = {};

profileService.fetchprofile = function () {
  return fetch({
    url: "/users/user/profile",
    method: "get",
  });
};

profileService.fetchOrgUsers = function (org_id) {
  return fetch({
    url: `/users/organization/members/`,
    method: "get",
  });
};

profileService.setSignature = function (data) {
  console.log(data);
  return fetch({
    url: `/users/user/${data?.id}`,
    method: "patch",
    data: data?.data
  });
};



export default profileService;

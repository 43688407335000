import fetch from "auth/FetchInterceptor";

const IntegrationService = {};

IntegrationService.getIntegrationSession = function () {
  return fetch({
    url: "/integration/get-session-token/",
    method: "get",
  });
};
IntegrationService.setIntegrationAccount = function () {
  return fetch({
    url: "/integration/create-link-account/",
    method: "get",
  });
};

IntegrationService.getApplicationList = function () {
  return fetch({
    url: "/integration/application-list/",
    method: "get",
  });
};

IntegrationService.getSlackAuthUri = function () {
  return fetch({
    url: "/slack/auth_uri",
    method: "get",
  });
};

IntegrationService.getSlackIsConnected = function () {
  return fetch({
    url: "/slack/is_connected",
    method: "get",
  });
};

export default IntegrationService;

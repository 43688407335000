const FirebaseConfig = {
  apiKey: "AIzaSyC3jagQz4HybKa5E3G8bpRlEoYet4O7PL8",
  authDomain: "revragai.firebaseapp.com",
  projectId: "revragai",
  storageBucket: "revragai.appspot.com",
  messagingSenderId: "28052513213",
  appId: "1:28052513213:web:bc9727080d9434fdf0f2d4",
  measurementId: "G-H1M56LHBTJ"
};

export default FirebaseConfig


import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `/signup`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    component: React.lazy(() => import("views/app-views/apps")),
  },
  {
    key: "apps.inbox",
    path: `${APP_PREFIX_PATH}/apps/inbox/*`,
    component: React.lazy(() => import("views/app-views/apps/mail")),
  },
  {
    key: "apps.mail-approval",
    path: `${APP_PREFIX_PATH}/apps/mail-approval/*`,
    component: React.lazy(() => import("views/app-views/apps/mailapproval")),
  },
  {
    key: "apps.mailmanagement",
    path: `${APP_PREFIX_PATH}/apps/mailmanagement/*`,
    component: React.lazy(() => import("views/app-views/apps/mailmanagement")),
  },
  {
    key: "apps.integrations",
    path: `${APP_PREFIX_PATH}/apps/integrations/*`,
    component: React.lazy(() => import("views/app-views/apps/integrations")),
  },
  {
    key: "apps.leads",
    path: `${APP_PREFIX_PATH}/apps/leads/*`,
    component: React.lazy(() => import("views/app-views/apps/leads")),
  },
  {
    key: "apps.leads-details",
    path: `${APP_PREFIX_PATH}/apps/lead-details/:id`,
    component: React.lazy(() => import("views/app-views/apps/lead-details")),
  },
  // {
  //     key: 'apps.chat',
  //     path: `${APP_PREFIX_PATH}/apps/chat/*`,
  //     component: React.lazy(() => import('views/app-views/apps/chat')),
  // },
  {
    key: "apps.campaign",
    path: `${APP_PREFIX_PATH}/apps/campaign/*`,
    component: React.lazy(() => import("views/app-views/apps/campaign")),
  },
  {
    key: "apps.settings",
    path: `${APP_PREFIX_PATH}/apps/settings/*`,
    component: React.lazy(() => import("views/app-views/apps/settings")),
  },
];

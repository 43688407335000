import React, { useState } from "react";
import { TourProvider } from "@reactour/tour";
import { Button } from "antd";

// Define the HOC
const withTour = (WrappedComponent, steps, tourOptions, onClose) => {
  const radius = 10;
  return (props) => {
    const [isOpen, setIsOpen] = useState(false); // State to update
    const onClickClose = () => {
      setIsOpen(true);
    };
    return (
      <TourProvider
        steps={steps}
        disableDotsNavigation={tourOptions.disableDotsNavigation}
        showDots={tourOptions.showDots}
        showBadge={tourOptions.showBadge}
        showNavigation={tourOptions.showNavigation}
        showCloseButton={false}
        scrollSmooth
        onClickMask={() =>{}}
        styles={{
          popover: (base) => ({
            ...base,
            "--reactour-accent": "#EFEDFD",
            borderRadius: radius,
          }),
          maskArea: (base) => ({ ...base, rx: radius }),
          // maskWrapper: (base) => ({ ...base, color: "#EFEDFD" }),
          badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
          controls: (base) => ({
            ...base,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
          }),
          close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
        }}
        nextButton={({ currentStep, setCurrentStep, steps }) => {
            console.log("currentStep==",currentStep);
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                className="reactour__prevButton"
                shape="round"
                onClick={onClickClose}
              >
                {"Skip"}
              </Button>
              <Button
                className="reactour__skipButton"
                shape="round"
                onClick={() => {
                    if (currentStep === steps.length - 1) {
                        onClickClose() // Loop back to the first step if it's the last step
                    } else {
                      setCurrentStep((s) => s + 1); // Move to the next step
                    }
                  }}
                style={{
                  background: "#5F26C8",
                  color: "#ffff",
                }}
              >
                {"Next"}
              </Button>
            </div>
          );
        }}
        prevButton={({}) => <div />}
      >
        <WrappedComponent {...props} setIsOpen={setIsOpen} isOpen={isOpen} />
      </TourProvider>
    );
  };
};

export default withTour;

import fetch from 'auth/FetchInterceptor'

const InboxService = {}

InboxService.getThreads = function (params) {
  return fetch({
    url: '/mail/threads/',
    method: 'get',
    params
  })
}

InboxService.getMails = function (id, params) {
    return fetch({
      url:  `/mail/mails/${id}/`,
      method: 'get',
      params
    })
  }

  InboxService.updateThread = function ({id,data}) {
  return fetch({
    url: `/mail/threads/${id}/`,
    method: 'put',
    data: data
  })
}

InboxService.postMail = function (data) {
  return fetch({
    url:  `/mail/email/local/reply_draft/`,
    method: 'post',
    data
  })
}

export default InboxService;
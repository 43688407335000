import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/login/access-token",
    method: "post",
    data: new URLSearchParams({
      grant_type: "",
      username: data?.email,
      password: data?.password,
      scope: "klkl",
      client_id: "",
      client_secret: "",
    }).toString(),
  });
};

AuthService.register = async (data) => {
  return fetch({
    url: "/users/signup",
    method: "post",
    data: data,
  });
};

AuthService.addUser = async (data) => {
  return fetch({
    url: "/users/add-user",
    method: "post",
    data: data,
  });
};
AuthService.updatePassword = async (data) => {
  return fetch({
    url: "/users/update-password",
    method: "POST",
    data: data,
  });
};

AuthService.resendActivationLink = async (token) => {
  return fetch({
    url: `/users/resend-verification-email/${token}`,
    method: "post",
  });
};

AuthService.deleteUser = async (userId) => {
  return fetch({
    url: `/users/user/${userId}`,
    method: "DELETE",
  });
};

AuthService.logout = function () {
  return fetch({
    url: "/auth/logout",
    method: "post",
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

// Add the sendResetPasswordLink method
AuthService.sendResetPasswordLink = function (email) {
  return fetch({
    url: `/users/forgot-password/${email}`,
    method: "post",
  });
};

// Add the resetPassword method
AuthService.resetPassword = function ({
  newPassword,
  confirmNewPassword,
  resetToken,
}) {
  return fetch({
    url: `/users/reset-password`,
    method: "post",
    data: {
      newPassword,
      confirmNewPassword,
      resetToken,
    },
  });
};

export default AuthService;

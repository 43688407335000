import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  signUpdata: null,
  addUserData: null,
  updatePasswordData: null,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  profileData: "",
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.login({ email, password });
      const token = response?.access_token;
      //TO DO : use cookies to store token and need to decide expiry
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    const { username, email, password, website } = data;
    try {
      const response = await AuthService.register({
        username,
        email,
        password,
        website,
      });
      const data = response;
      //TO DO : use cookies to stire token and need to decide expiry
      // localStorage.setItem(AUTH_TOKEN, token);
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const addUser = createAsyncThunk(
  "auth/addUser",
  async (data, { rejectWithValue }) => {
    const { username, email, password, role } = data;
    try {
      const response = await AuthService.addUser({
        username,
        email,
        password,
        role,
      });
      const data = response;
      console.log("resposne---->", data);
      //TO DO : use cookies to stire token and need to decide expiry
      // localStorage.setItem(AUTH_TOKEN, token);
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (data, { rejectWithValue }) => {
    const { confirmPassword, currentPassword, newPassword } = data;
    try {
      const response = await AuthService.updatePassword({
        confirmPassword,
        currentPassword,
        newPassword,
      });
      const data = response;
      console.log("resposne---->", data);
      //TO DO : use cookies to stire token and need to decide expiry
      // localStorage.setItem(AUTH_TOKEN, token);
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data || "Error");
    }
  }
);

export const resendActivation = createAsyncThunk(
  "auth/resendActivation",
  async (data, { rejectWithValue }) => {
    const { signup_token } = data;
    try {
      const response = await AuthService.resendActivationLink(signup_token);
      const data = response;
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async (data, { rejectWithValue }) => {
    const { userId } = data;
    try {
      const response = await AuthService.deleteUser(userId);
      const data = response;
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      // const response = await AuthService.loginInOAuth();
      const response = await FirebaseService.signInGoogleRequest();
      console.log("response--- google", response);
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const sendResetPasswordLink = createAsyncThunk(
  "auth/sendResetPasswordLink",
  async (email, { rejectWithValue }) => {
    try {
      const response = await AuthService.sendResetPasswordLink(email);
      console.log("full response", response);
      if (response.status === "success") {
        return {
          status: response.status,
          message: response.message,
        };
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data?.detail || "Error");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ newPassword, confirmNewPassword, resetToken }, thunkAPI) => {
    try {
      const response = await AuthService.resetPassword({
        newPassword,
        confirmNewPassword,
        resetToken,
      });
      console.log("response", response);
      if (response.status === "success") {
        return {
          status: response.status,
          message: response.message,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.detail || error.message
      );
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.signUpdata = action.payload;
        // state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(addUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        // state.token = action.payload;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        // state.token = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(resendActivation.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendActivation.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        // state.token = action.payload;
      })
      .addCase(resendActivation.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.updatePasswordData = action.payload;
        // state.token = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      // Add cases for sendResetPasswordLink
      .addCase(sendResetPasswordLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(sendResetPasswordLink.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      // Add cases for resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.showMessage = true;
        state.message = action.payload;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;

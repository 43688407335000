import { useDispatch, useSelector } from "react-redux";

import { fetchprofileData, fetchOrgUsersData ,setSignature} from "store/slices/profileSlice";

export const useProfile = () => {
  const dispatch = useDispatch();
  const showMessage = useSelector((state) => state.profile.showMessage);
  const error = useSelector((state) => state.profile.error);
  const profileData = useSelector((state) => state.profile.profileData);
  const orgUserData = useSelector((state) => state.profile.members);

  const get_user_Profile = () => {
    dispatch(fetchprofileData());
  };
  const get_org_users = () => {
    dispatch(fetchOrgUsersData());
  };
  const set_signature = (data) =>{
    console.log("sendersetting Data",data)
    dispatch(setSignature(data))
  }

  return {
    showMessage,
    error,
    profileData,
    get_user_Profile,
    orgUserData,
    get_org_users,
    set_signature
  };
};

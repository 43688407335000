import fetch from 'auth/FetchInterceptor'

const CampaignService = {}

// Get campaign List

CampaignService.getCampaign = function () {
  return fetch({
    url: '/campaign/campaign',
    method: 'get',
  })
}

// Create campaign 

CampaignService.createCampaign = function (data) {
  return fetch({
    url: '/campaign/campaign',
    method: 'post',
    data: {
      campaignName: data
    }
  })
}

// Update campaign

CampaignService.updateCampaign = function (data) {
  return fetch({
    url: `/campaign/campaign/${data?.campaignId}`,
    method: 'put',
    data: data
  })
}

// Getting sample leads 

CampaignService.getSampleLeads = function (id) {
  return fetch({
    url: `/campaign/samples/${id}`,
    method: 'get',
  })
}

// Getting sample leads 

CampaignService.duplicateCampaign = function (id) {
  return fetch({
    url: `/campaign/duplicate/${id}`,
    method: 'get',
  })
}

// Getting individual campaign info

CampaignService.getCampaignInfo = function (id) {
  return fetch({
    url: `/campaign/info/${id}`,
    method: 'get',
  })
}

// Pause campaign 

CampaignService.pauseCampaign = function (id) {
  return fetch({
    url: `/campaign/pause/${id}`,
    method: 'put',
  })
}

// Live campaign 

CampaignService.liveCampaign = function (id) {
  return fetch({
    url: `/campaign/live/${id}`,
    method: 'put',
  })
}

// Visibility Campaign

CampaignService.updateVisibility = function (data) {
  return fetch({
    url: `/campaign/visibilty/${data?.campaignId}?status=${data?.status}`,
    method: 'put',
  })
}

// Publish campaign 

CampaignService.publishCampaign = function (id) {
  return fetch({
    url: `/campaign/publish/${id}`,
    method: 'put',
  })
}

// Getting playbooks 

CampaignService.getPlaybooks = function (id) {
  return fetch({
    url: `/campaign/playbooks`,
    method: 'get',
  })
}

// fetch playbooks samples

CampaignService.getPlaybookSample = function (data) {
  return fetch({
    url: `/campaign/playbook/sample`,
    method: 'post',
    data: data
  })
}

// fetch playbooks samples

CampaignService.fetchCampaignTiming = function (id) {
  return fetch({
    url: `/campaign/runtime/${id}`,
    method: 'get',
  })
}

// fetch getCustomizeSamplePlaybook samples

CampaignService.getCustomizeSamplePlaybook = function (data) {
  return fetch({
    url: `/campaign/playbook/sample/edit`,
    method: 'post',
    data: data
  })
}

// upload csv file 

CampaignService.uploadCsv = function (data) {
  return fetch({
    url: `/campaign/upload/leads`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

// upload blacklist csv file 

CampaignService.uploadblackListCsv = function (data) {
  return fetch({
    url: `/campaign/upload/email_blacklist_domains`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

// upload filter csv file 

CampaignService.uploadtargetCsv = function (data) {
  return fetch({
    url: `/campaign/upload/target_filter_csv`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

// upload blacklist csv file 

CampaignService.getLeads = function (id) {
  return fetch({
    url: `/campaign/lead-count/${id}`,
    method: 'get',
  })
}

// upload blacklist csv file 

CampaignService.deleteCampaign = function (id) {
  return fetch({
    url: `/campaign/campaign/draft/${id}`,
    method: 'delete',
  })
}

export default CampaignService
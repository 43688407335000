import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Mixpanel } from "mixpanel-wrapper";
import profileService from "services/profileService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  profileData: null,
  members: null,
};

// useprofile Data
export const fetchprofileData = createAsyncThunk(
  "auth/fetchprofileData",
  async () => {
    const response = await profileService.fetchprofile();
    Mixpanel.identify(response.email);
    Mixpanel.people.set({
      $user_name: response.username,
    });
    return response;
  }
);

// use org data Data
export const fetchOrgUsersData = createAsyncThunk(
  "auth/fetchOrgUserData",
  async () => {
    const response = await profileService.fetchOrgUsers();
    Mixpanel.identify(response.email);
    Mixpanel.people.set({
      $user_name: response.username,
    });
    return response;
  }
);

export const setSignature = createAsyncThunk(
  "auth/setSignature",
  async (data) => {
    try {
      const response = await profileService.setSignature(data);
      return response;
    } catch (err) {
      return err.response?.message || 'Error'
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchprofileData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchprofileData.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchprofileData.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(fetchOrgUsersData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrgUsersData.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchOrgUsersData.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearError } = profileSlice.actions; // not used yet

export default profileSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MailApprovalService from "services/MailApprovalService";
import { message } from "antd";
import { clearCount } from "./inboxSlice";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  error: null,
  generationLogs: [],
  generationLogsCount: 0,
  fullGenerationLogs: [],
  generationResponse: [],
  generateResponse: {},
  reGenerateResponse: {},
  approveMailRes: {},
  approveAllMailRes: null,
  editEmailRes: null,
  updateGenerationLogRes: null,
};

const success = (msg) => {
  message.open({
    type: "success",
    content: msg,
  });
};

// Fetch fetchGenerationLogs
export const fetchGenerationLogs = createAsyncThunk(
  "mailGeneration/fetchGenerationLogs",
  async (params, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.getGenerationlogs(params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching logs"
      );
    }
  }
);

// Fetch fetchGenerationResponse
export const fetchGenerationResponse = createAsyncThunk(
  "mailGeneration/fetchGenerationResponse",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.getGenerationResponse(
        id,
        params
      );
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching emails"
      );
    }
  }
);

export const fetchGenerateResponse = createAsyncThunk(
  "mailGeneration/fetchGenerateResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.generateResponse(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error generating response"
      );
    }
  }
);

export const fetchReGenerateResponse = createAsyncThunk(
  "mailGeneration/fetchReGenerateResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.reGenerateResponse(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error generating response"
      );
    }
  }
);

export const updateGenrationResponse = createAsyncThunk(
  "mailGeneration/updateGenrationResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.updateGenerationResponse(data);
      success("Successfully updated");
      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationResponse"
      );
    }
  }
);

export const approveMailResponse = createAsyncThunk(
  "mailGeneration/putApproveMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.approveMail(data);
      success("Successfully approved");

      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationResponse"
      );
    }
  }
);

export const approveAllMailResponse = createAsyncThunk(
  "mailGeneration/putApproveAllMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.approveAllMail(data);
      success("Successfully approved");
      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationResponse"
      );
    }
  }
);

export const updateGenerationLog = createAsyncThunk(
  "mailGeneration/updateGenerationLog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailApprovalService.updateGenerationLog(data);
      console.log(":response---", response);
      if (data?.data?.hasOwnProperty("isDeleted")) {
        success("Successfully deleted mail");
      }
      return { payload: data, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error updateGenerationLog"
      );
    }
  }
);

export const mailGeneration = createSlice({
  name: "mailGeneration",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
    cleargenerationCount(state) {
      state.generationLogsCount = 0
    },
    cleargenerationLog(state) {
      state.generationLogs = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenerationLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenerationLogs.fulfilled, (state, action) => {
        state.loading = false;
        // const currentState = current(state);
        state.generationLogs = action?.payload?.logs || [];
        state.generationLogsCount = action?.payload?.logCount || 0;
        state.showMessage = false;
      })
      .addCase(fetchGenerationLogs.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(fetchGenerationResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenerationResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.generationResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchGenerationResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      // generate
      .addCase(fetchGenerateResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenerateResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.generateResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchGenerateResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      // regenerate
      .addCase(fetchReGenerateResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReGenerateResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.reGenerateResponse = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchReGenerateResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      .addCase(updateGenrationResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGenrationResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.editEmailRes = action.payload;
        state.showMessage = false;
      })
      .addCase(updateGenrationResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(approveMailResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveMailResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.approveMailRes = action.payload;
        state.showMessage = false;
      })
      .addCase(approveMailResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(approveAllMailResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveAllMailResponse.fulfilled, (state, action) => {

        state.loading = false;
        state.approveAllMailRes = action.payload;
        state.showMessage = false;
      })
      .addCase(approveAllMailResponse.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(updateGenerationLog.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGenerationLog.fulfilled, (state, action) => {
        state.loading = false;
        state.updateGenerationLogRes = action.payload;
        state.showMessage = false;
      })
      .addCase(updateGenerationLog.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearError, cleargenerationCount,cleargenerationLog } = mailGeneration.actions; // not used yet

export default mailGeneration.reducer;

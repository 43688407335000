import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import InboxService from "services/InboxService";
import { message } from "antd";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  mails: {},
  error: null,
  threads: [],
  postMailRes: {},
  updateThreadShowMessage: {
    updated: false,
  },
  count: null,
};

const success = (msg) => {
  message.open({
    type: "success",
    content: msg,
  });
};

// Fetch threads
export const fetchThreads = createAsyncThunk(
  "inbox/fetchThreads",
  async (params, { rejectWithValue }) => {
    try {
      const response = await InboxService.getThreads(params);
      console.log(params,"params--response--", response);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching threads"
      );
    }
  }
);

// Fetch mails
export const fetchMails = createAsyncThunk(
  "inbox/fetchMails",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await InboxService.getMails(id, params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Error fetching emails"
      );
    }
  }
);

export const updateThread = createAsyncThunk(
  "inbox/updateThread",
  async (thread, { rejectWithValue }) => {
    try {
      const response = await InboxService.updateThread(thread);
      if (
        thread?.data?.hasOwnProperty("isDeleted") ||
        thread?.data?.hasOwnProperty("isStarred")
      ) {
        success("Successfully updated");
      }
      return { thread, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error toggling mailbox"
      );
    }
  }
);

export const postMail = createAsyncThunk(
  "inbox/postMail",
  async (thread, { rejectWithValue }) => {
    try {
      const response = await InboxService.postMail(thread);
      success("Email sent successfully");
      return { thread, data: response };
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error toggling mailbox"
      );
    }
  }
);

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
    clearThreads(state) {
      state.threads = [];
      state.mails = {};
      state.count = null;
    },
    clearCount(state) {
      state.count = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch threads
      .addCase(fetchThreads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchThreads.fulfilled, (state, action) => {
        state.loading = false;
        state.threads = action.payload?.data || [];
        state.count = action.payload?.count || null;
        state.showMessage = false;
      })
      .addCase(fetchThreads.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(updateThread.pending, (state) => {
        state.loading = true;
        state.updateThreadShowMessage = {
          updated: false,
        };
      })
      .addCase(updateThread.fulfilled, (state, action) => {
        console.log("action----", action.payload);
        state.updateThreadShowMessage = {
          updated: true,
          response: action.payload,
        };
        state.loading = false;
      })
      .addCase(updateThread.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.updateThreadShowMessage = {
          updated: false,
        };
      })
      // Toggle mails
      .addCase(fetchMails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMails.fulfilled, (state, action) => {
        state.loading = false;
        state.mails = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchMails.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      // Toggle mails
      .addCase(postMail.pending, (state) => {
        state.loading = true;
      })
      .addCase(postMail.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("action.payload",action.payload);
        state.mails.mails = [
          ...(state.mails.mails || []),
          action.payload.data.email_detail,
        ];
        state.showMessage = false;
      })
      .addCase(postMail.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearError, clearThreads, clearCount } = inboxSlice.actions; // not used yet

export default inboxSlice.reducer;

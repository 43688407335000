import fetch from "auth/FetchInterceptor";

const LeadService = {};

LeadService.getLeads = function (params) {
  return fetch({
    url: "/leads/lists/",
    method: "get",
    params,
  });
};

LeadService.fetchLeads = function (params) {
  return fetch({
    url: "/leads/leads-filter/",
    method: "get",
    params,
  });
};

LeadService.getLeadDetail = function (id, params) {
  return fetch({
    url: `/leads/lead-details/${id}/`,
    method: "get",
    params,
  });
};

export default LeadService;

import axios from "axios";
import { notification } from "antd";
import { signOutSuccess } from "store/slices/authSlice";
import store from "../store";
import { AUTH_TOKEN } from "constants/AuthConstant";

// const API_BASE_URL =
//   `${process.env.REACT_APP_API_ENDPOINT}/api/v1` ||
//   "https://api.revrag.ai/api/v1";

const API_BASE_URL = "https://api-dev.revrag.ai/api/v1";
// const API_BASE_URL = "https://e409-103-214-63-146.ngrok-free.app/api/v1";
// const API_BASE_URL = "https://api.revrag.ai/api/v1"

const unauthorizedCode = [401, 403];

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    // we shoukd store it in cookies
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    notification.error({
      message: "Error",
      description: "Request error",
    });
    return Promise.reject(error);
  }
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
      description: "",
    };

    if (error.response && unauthorizedCode.includes(error.response.status)) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please login again";
      localStorage.removeItem(AUTH_TOKEN);

      store.dispatch(signOutSuccess());
    }

    if (error.response && error.response.status === 400) {
      notificationParam.message = "Error";
      notificationParam.description = error.response.data.detail
        ? error.response.data.detail
        : "The requested resource was not found";
    }

    if (error.response && error.response.status === 404) {
      notificationParam.message = "Not Found";
      notificationParam.description = "The requested resource was not found";
    }

    if (error.response && error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
      notificationParam.description = "An error occurred on the server";
    }

    if (error.response && error.response.status === 508) {
      notificationParam.message = "Time Out";
      notificationParam.description = "The request timed out";
    }

    if (error.response === undefined) {
      notificationParam.message = "Internal server Error";
      notificationParam.description = "An error occurred on the server";
    }

    if (error.response && error.response.data && error.response.data.detail) {
      notificationParam.message = "Error";
      notificationParam.description = error.response.data.detail;
    }

    notification.error(notificationParam);
    return Promise.reject(error);
  }
);

export default service;

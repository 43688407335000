import fetch from "auth/FetchInterceptor";

const MetaDataService = {};

// People
MetaDataService.getjobTitles = function () {
  return fetch({
    url: "/campaign/listing/job-titles_v1",
    method: "get",
  });
};
MetaDataService.getjSeniortyLevel = function () {
  return fetch({
    url: '/campaign/listing/seniority_level',
    method: 'get',
  })
}
MetaDataService.getfunctionandDivisions = function () {
  return fetch({
    url: "/campaign/listing/function_and_division",
    method: "get",
  });
};

// Location
MetaDataService.getCountry = function () {
  return fetch({
    url: "/campaign/listing/country",
    method: "get",
  });
};
MetaDataService.getContinent = function () {
  return fetch({
    url: "/campaign/listing/continent",
    method: "get",
  });
};
MetaDataService.getRegion = function () {
  return fetch({
    url: "/campaign/listing/region",
    method: "get",
  });
};


// Company
MetaDataService.getCompanySize = function () {
  return fetch({
    url: "/campaign/listing/company-size",
    method: "get",
  });
};
MetaDataService.getRevenue = function () {
  return fetch({
    url: "/campaign/listing/revenue",
    method: "get",
  });
};
MetaDataService.getFunding = function () {
  return fetch({
    url: "/campaign/listing/funding",
    method: "get",
  });
};
MetaDataService.getIndustrysector = function () {
  return fetch({
    url: "/campaign/listing/industry_sector",
    method: "get",
  });
};

// Persona service
MetaDataService.getPersona = function () {
  return fetch({
    url: "/campaign/listing/persona",
    method: "get",
  });
};




MetaDataService.getSectors = function () {
  return fetch({
    url: "/campaign/sectors",
    method: "get",
  });
};

MetaDataService.getOutboundKeywords = function () {
  return fetch({
    url: "/campaign/outbound_keywords",
    method: "get",
  });
};

MetaDataService.getAdvancedTargetting = function () {
  return fetch({
    url: "/campaign/advanced_targeting",
    method: "get",
  });
};

MetaDataService.getToneOfVoice = function () {
  return fetch({
    url: "/campaign/tone_of_voice",
    method: "get",
  });
};

MetaDataService.getOptimization = function () {
  return fetch({
    url: "/campaign/listing/optimisation_metrics",
    method: "get",
  });
};

export default MetaDataService;

import {createSlice, createAsyncThunk, current} from '@reduxjs/toolkit';
import DashboardService from 'services/DashboarService';
import LeadService from 'services/LeadService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    graphData: {},
    pendingEmail:0,
    queuedEmail:0,
    error: null,
};

// Fetch graphdata
export const fetchGraphData = createAsyncThunk('dashboad/fetchGraphData', async (data, {rejectWithValue}) => {
    try {
        const response = await DashboardService.getGraphData(data)
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error fetching graph data');
    }
});
 

// Fetch pendingEmails
export const fetchpendingEmail = createAsyncThunk('dashboad/fetchpendingEmail', async (id, {rejectWithValue}) => {
    try {
        const response = await DashboardService.getPendingEmail(id)
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error fetching graph data');
    }
});
 

// Fetch Quesued Emails
export const fetchqueuedEmail = createAsyncThunk('dashboad/fetchqueuedEmail', async (id, {rejectWithValue}) => {
    try {
        const response = await DashboardService.getQueuedEmail(id)
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error fetching graph data');
    }
});


export const dashboardSlice = createSlice({
    name: 'dashboad',
    initialState,
    reducers: {
        clearError(state) {
            state.error = null;
            state.showMessage = false;
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch dashboad graph data
            .addCase(fetchGraphData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGraphData.fulfilled, (state, action) => {
                state.loading = false;
                state.graphData = action.payload;
                state.showMessage = false;
            })
            .addCase(fetchGraphData.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })

             // Fetch Pending Email data
             .addCase(fetchpendingEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchpendingEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.pendingEmail = action.payload;
                state.showMessage = false;
            })
            .addCase(fetchpendingEmail.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })

             // Fetch Queued Email data
             .addCase(fetchqueuedEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchqueuedEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.queuedEmail = action.payload;
                state.showMessage = false;
            })
            .addCase(fetchqueuedEmail.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })
    }
});

export const {clearError} = dashboardSlice.actions; // not used yet

export default dashboardSlice.reducer;
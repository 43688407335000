import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import LeadService from "services/LeadService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  leads: [],
  leadDetail: {},
  total_count: 0,
  error: null,
  threads: [],
};

// Fetch threads
export const getLeads = createAsyncThunk(
  "leads/getLeads",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LeadService.getLeads(params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching threads"
      );
    }
  }
);

// Fetch threads
export const fetchLeads = createAsyncThunk(
  "leads/fetchLeads",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LeadService.fetchLeads(params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching threads"
      );
    }
  }
);

// Fetch mails
export const fetchLeadDetails = createAsyncThunk(
  "leads/fetchLeadDetails",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await LeadService.getLeadDetail(id, params);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching emails"
      );
    }
  }
);

export const inboxSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.showMessage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch leads
      .addCase(fetchLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.leads = action.payload.leads;
        state.total_count = action.payload.total_count;
        state.showMessage = false;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      })

      // Toggle leadDetails
      .addCase(fetchLeadDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeadDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.leadDetail = action.payload;
        state.showMessage = false;
      })
      .addCase(fetchLeadDetails.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearError } = inboxSlice.actions; // not used yet

export default inboxSlice.reducer;
